.privacy-content {
  margin-left: 120px;
  margin-right: 120px;
  text-align: left !important;
}
.privacy-heading {
  font-size: 45px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bolder;
  color: #444444;
  margin: 120px 0px 30px 115px;
}
@media (max-width: 768px) {
  .privacy-content {
    margin-left: 20px;
    margin-right: 20px;
    text-align: left !important;
  }
  .privacy-heading {
    margin: 100px 0px 30px 20px;
    font-size: 25px;
  }
  }