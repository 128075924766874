.video-container {
  text-align: center;
  background-color: #f5f5f5;
  padding: 20px;
}

.video-wrapper {
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin: 0 auto;
}

.video {
  max-width: 100%;
  height: auto;
  display: block;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 24px;
  color: #333;
  margin: 0;
  padding: 10px 0;
}
