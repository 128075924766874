.small-heading {
  color: grey;
  font-weight: bolder;
  font-size: 21px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.content {
  letter-spacing: 0.7px;
}
