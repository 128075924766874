.features-heading {
  font-size: 45px;
  font-family: "Ubuntu", sans-serif;
  font-weight: bolder;
  color: #444444;
  margin-top: 90px !important;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px;
}

.features-card {
  margin: 26px;
  width: 21.3rem;
  text-align: center;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.041);
  border-radius: 8px;
  background-color: #ffffff;
  transition: transform 0.3s, box-shadow 0.3s;
}

.features-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 16px 10px rgba(0, 0, 0, 0.116);
}

.card-text {
  margin-top: 20px;
}

.proj-img-1 {
  margin-left: -12px;
  width: 24rem;
  border-radius: 8px;
}

.features-img {
  width: 90px;
  display: flex;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 10px;
}

.features-title {
  color: #444444;
}

.features-desc {
  font-size: 15px;
  margin-bottom: 7px !important;
}
@media (max-width: 768px) {
  .features-heading {
    font-size: 35px;
  }
  }