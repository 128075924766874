.contactus-section {
  padding-top: 5%;
}

.section-contact {
  background-color: white;
}

.contact-title {
  font-size: 30px;
  font-weight: 600;
  color: #614dff;
}

.contact-subtitle {
  font-size: 16px;
  font-weight: 400;
  color: black;
}

form {
  max-width: 855px;
  margin: auto;
}

.submit-btn-div-message {
  display: flex;
  justify-content: center;
}

.btn-submit-msg {
  height: 40px;
  cursor: pointer;
  background: #614dff !important;
  border: none !important;
  width: 120px;
  border-radius: 25px;
}

.toast {
  position: absolute !important;
  right: 10px;
  margin-top: 15px;
}
.contact-form-label {
  color: #614dff;
}
@media (max-width: 1200px) {
  .section-contact {
    padding: 0% 10% 2% 10%;
  }
}
@media (max-width: 768px) {
  .contactus-img {
    height: 340px;
  }

  .contactus-section-heading {
    font-weight: 500;
    font-size: 25px;
  }
  .contact-title {
    font-size: 25px;
    font-weight: 500;
    color: #614dff;
  }
  .contact-subtitle {
    font-size: 12px;
  }
}
