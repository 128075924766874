.myNavbar {
  height: 84px;
  margin-bottom: -100px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 1000;
}

.logo {
  width: 160px;
}

.navbar-nav {
  margin-left: auto;
}
.custom-contact-button {
  display: flex;
  align-items: center;
  text-align: center;
  background: none;
  border: none;
  color: #614dff;
  padding: 0;
  text-decoration: none;
  font-size: 16px;
  position: relative;
  margin-left: 30px;
}

.custom-contact-button::before,
.custom-contact-button::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  width: 90px;
  background: #614dff;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.custom-contact-button::before {
  top: 100%;
  transform: scaleX(1);
}

.custom-contact-button::after {
  top: 100%;
}

.custom-contact-button:focus {
  outline: none;
}

.custom-contact-button span {
  position: relative;
  z-index: 1;
  background-color: #fff;
  padding: 0 10px;
}

@media (max-width: 992px) {
  .navbar-nav {
    background-color: white;
    padding: 20px;
    border-radius: 7px;
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.11);
    transition: transform 0.3s, box-shadow 0.3s, max-height 0.3s;
  }

  .dropdown-menu {
    margin-left: 0px;
  }
  .custom-contact-button {
    margin-left: -2px;
    margin-top: 8px;
  }
  .custom-contact-button::before,
  .custom-contact-button::after {
    width: 80px;
  }
}

@media (max-width: 768px) {
  .custom-contact-button {
    margin-left: -2px;
    margin-top: 8px;
  }

  .custom-contact-button::before,
  .custom-contact-button::after {
    width: 80px;
  }
}
