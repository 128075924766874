.about-small-heading {
  color: grey;
  font-weight: bolder;
  font-size: 21px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.about-main-heading {
  margin-top: -40px;
  color: #333333;
  font-weight: bolder;
  font-size: 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .about-main-heading {
    font-size: 26px;
  }
  }