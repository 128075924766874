.layout-heading {
  font-size: 45px;
  font-family: "Ubuntu", sans-serif;
  font-weight: bolder;
  color: #444444;
  margin-top: 90px !important;
}

.layouts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px;
}

.layout-card {
  margin: 26px;
  width: 24.13rem;
  text-align: center;
  box-shadow: 0 10px 15px 10px rgba(0, 0, 0, 0.103);
  border-radius: 8px;
  background-color: #ffffff;
  transition: transform 0.3s, box-shadow 0.3s;
  
}

.layout-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 21px 15px rgba(0, 0, 0, 0.103);
}

.card-text {
  margin-top: 20px;
}

.layout2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px;
}

.layout-card2 {
  margin: 26px;
  width: 17.3rem;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.041);
  border-radius: 8px;
  background-color: #ffffff;
  transition: transform 0.3s, box-shadow 0.3s;
}

.layout-card2:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 16px 10px rgba(0, 0, 0, 0.116);
}

.layout-img-1 {
  margin-left: -12px;
  width: 24rem;
  border-radius: 8px;
  height: 200px; 
  object-fit: cover; 
  border-radius: 8px;
}

.layout-title2 {
  color: #444444;
  font-size: 18px;
  font-weight: bolder;
}

.layout-img1 {
  font-size: 100px;
  color: #6272c7;
  margin-top: -27px !important;
}

.layout-img2 {
  font-size: 100px;
  color: #ffc050;
  margin-top: -27px !important;
}

.layout-img3 {
  font-size: 100px;
  color: #6272c7;
  margin-top: -27px !important;
}

.layout-img4 {
  font-size: 100px;
  color: #ffc050;
  margin-top: -27px !important;
}
.project-title {
  font-weight: bold;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .layout-heading {
    font-size: 35px;
 
  }
  }