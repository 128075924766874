.responsive-heading {
  font-size: 45px;
  font-family: "Ubuntu", sans-serif;
  font-weight: bolder;
  color: #444444;
  margin: 40px !important;
}
.responsive-pera {
  margin-left: 40px;
  font-family: "Ubuntu", sans-serif;
}
.delivery-section-img {
  height: 85%;
  width: 85%;
}

.responsive-container {
  background-color: #f3f3f3;
  overflow-x: hidden !important;
}
@media (max-width: 768px) {
  .delivery-list {
    margin-left: 0px;
    width: 100%;
    font-size: 21px;
  }
  .delivery-container {
    overflow-x: hidden !important;
  }
  .responsive-heading {
    font-size: 35px;

  }
}
