.logo-footer {
    width: 200px;
    height: 68px;
    margin-bottom: 5%;
  }
  .section-footer {
    font-family: 'Ubuntu', sans-serif;
    margin-top: 80px;
    width: 100%;
    overflow-x: hidden !important;
  }
  
  .footer-bs {
    background-color: #f3f3f3;
    padding: 25px 240px;
    color: black;
    font-size: 14px;
  }
  
  .footer-bs .footer-brand,
  .footer-bs .footer-nav,
  .footer-bs .footer-social,
  .footer-bs .footer-ns {
    padding: 10px 25px;
  }
  
  .footer-bs .footer-nav,
  .footer-bs .footer-social,
  .footer-bs .footer-ns {
    border-color: transparent;
  }
  
  .footer-bs .footer-brand h2 {
    margin: 0px 0px 10px;
  }
  
  .footer-bs .footer-brand p {
    font-size: 12px;
    color: black;
  }
  
  .footer-bs .footer-nav ul.pages {
    list-style: none;
    padding: 0px;
  }
  
  .footer-bs .footer-nav ul.pages li {
    padding: 5px 0px;
  }
  
  .footer-bs .footer-nav ul.pages a {
    color: black;
    text-decoration: none !important;
  }
  
  .footer-bs .footer-nav ul.pages a:hover {
    color: #0f7bd4;
    text-decoration: none;
  }
  
  .footer-bs .footer-nav h4 {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 10px;
  }
  
  .footer-bs .footer-nav ul.list {
    list-style: none;
    padding: 0px;
  }
  
  .footer-bs .footer-nav ul.list li {
    padding: 5px 0px;
  }
  
  .footer-bs .footer-nav ul.list a {
    color: black;
    text-decoration: none !important;
  }
  
  .footer-bs .footer-nav ul.list a:hover {
    color: #0f7bd4;
    text-decoration: none !important;
  }
  
  .footer-bs .footer-social ul {
    list-style: none;
    padding: 0px;
  }
  
  .footer-bs .footer-social h4 {
    font-size: 11px;
    letter-spacing: 3px;
  }
  
  .footer-bs .footer-social li {
    padding: 5px 4px;
  }
  
  .footer-bs .footer-social a {
    color: black;
    text-decoration: none;
  }
  
  .footer-bs .footer-social a:hover {
    color: #0f7bd4;
    text-decoration: none;
  }
  
  .footer-bs .footer-ns h4 {
    font-size: 11px;
    letter-spacing: 3px;
    margin-bottom: 10px;
  }
  
  .footer-bs .footer-ns p {
    font-size: 12px;
    color: black;
  }
  
  .input-group-btn {
    background-color: #614DFF;
    margin-inline: auto !important;
    margin-top: -5px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .footer-bottom {
    width: 100%;
    height: 40px;
    background-color: #614DFF;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    padding-left: 250px;
    padding-right: 280px;
  }
  
  .footer-devices-content {
    padding-top: 20px;
  }
  
  .footer-app-title {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }
  
  .footer-store-images {
    width: 190px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-bottom: 20px;
  }
  
  .footer-store-images:hover {
    transform: scale(1.1);
  }
  .footer-heading {
    font-weight: bolder;
  }
  
  @media (min-width: 768px) {
    .footer-bs .footer-nav,
    .footer-bs .footer-social,
    .footer-bs .footer-ns {
      border-left: solid 1px rgba(255, 255, 255, 0.1);
    }
    .section-footer {
      overflow-x: hidden !important;
    }
  }
  
  @media (max-width: 1500px) {
    .footer-bs {
      padding: 0 !important;
    }
  }
  
  @media (max-width: 576px) {
    .footer-bottom {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  